const ENV_CONFIG = {
  prod: {
    service: 'https://auth-main.aiml.asu.edu',
    appName: 'betaland',
    appId: '8kouuV6UxHAr5kobaBZmZV',
    contentUrls: {
      asugpt: 'https://asugpt.aiml.asu.edu',
      showdown: 'https://showdown.aiml.asu.edu',
      compare: 'https://compare.aiml.asu.edu',
      crowbot: 'https://crowbot.aiml.asu.edu'
    }
  },
  beta: {
    service: 'https://auth-main-beta.aiml.asu.edu',
    appName: 'betaland',
    appId: '8kouuV6UxHAr5kobaBZmZV',
    contentUrls: {
      asugpt: 'https://asugpt-beta.aiml.asu.edu',
      showdown: 'https://showdown-beta.aiml.asu.edu',
      compare: 'https://compare-beta.aiml.asu.edu',
      crowbot: 'https://crowbot-beta.aiml.asu.edu'
    }
  },
  poc: {
    service: 'https://auth-main-poc.aiml.asu.edu',
    appName: 'betaland',
    appId: '8kouuV6UxHAr5kobaBZmZV',
    contentUrls: {
      asugpt: 'https://asugpt-poc.aiml.asu.edu',
      showdown: 'https://showdown-poc.aiml.asu.edu',
      compare: 'https://compare-poc.aiml.asu.edu',
      crowbot: 'https://crowbot-poc.aiml.asu.edu'
    }
  }
};

export default ENV_CONFIG;
