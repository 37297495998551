import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import ENV_CONFIG from '../config/env-config';
import { AUTH_HOST } from './constants';

export function validCookieToken(): boolean {
  // You need to define this cookie by hand in your browser if working locally to avoid being redirected to the production application.
  const cookie = window.location.hostname.split('.')[0];
  const jwtToken = Cookies.get(cookie);
  if (jwtToken) {
    const parsedToken = jwtDecode(jwtToken);
    const currentTime = Math.floor(Date.now() / 1000);

    return !!parsedToken.exp && parsedToken.exp >= currentTime;
  }

  return false;
}

// GET APP ENVIRONMENT ACCORDING TO CURRENT HOST
export const getAppEnv = () => {
  const { hostname } = window.location;
  let environment = ENV_CONFIG.prod;

  if (hostname.includes('beta')) environment = ENV_CONFIG.beta;
  if (hostname.includes('poc') || hostname.includes('localhost')) environment = ENV_CONFIG.poc;

  return environment;
};

export const navigateToLogin = () => {
  const { href } = window.location;
  const environment = getAppEnv();

  // navigate to login
  const route = `${AUTH_HOST}/login?service=${environment.service}/${environment.appName}/?aid=${environment.appId}%26redirect=${href}`;
  window.location.replace(route);
};

export const mapContent = () => {
  const appEnv = getAppEnv();
  const CONTENT = [
    {
      x: 1228,
      y: 300,
      name: 'ASU GPT',
      url: appEnv.contentUrls.asugpt,
      shortText: "Using ASU's data, this chatbot provides insightful guidance for enhanced learning",
      mediumText:
        "ASU GPT is your AI companion powered by ASU's own data. This chatbot, trained on the vast knowledge of Arizona State University, offers information, guidance, and insights to enhance your learning experience."
    },
    // {
    //   x: 292,
    //   y: 310,
    //   name: 'Chembot Tutor Assist',
    //   shortText:
    //     'AI-driven tutoring streamlines preparation and constructs personalized study guides, optimizing the learning experience.',
    //   mediumText:
    //     'AI-driven tutoring optimizes the process, streamlining prep, constructing personalized study guides, and crafting test questions, all based on course content. This empowers tutors to offer highly personalized, engaging guidance.'
    // },
    {
      x: 601,
      y: 103,
      name: 'Model showdown',
      url: appEnv.contentUrls.showdown,
      shortText: 'Select between two anonymous AI models competing head to head for the best answer.',
      mediumText:
        "Observe two anonymous AI models in a battle of wits. Craft a challenge, the countdown starts, evaluate the model's responses. Can you pick the AI with the best answer before time's up?"
    },
    {
      x: 458,
      y: 461,
      name: 'Model Comparison',
      url: appEnv.contentUrls.compare,
      shortText: 'Users select models, detect biases, enhance fairness, and assess content quality.',
      mediumText:
        'This Model Comparison app at ASU empowers users to select from multiple Large Language Models, facilitating evaluations to detect biases, promote fairness, and gauge overall content quality.'
    }
    // ,
    // {
    //   x: 812,
    //   y: 298,
    //   name: 'CrowBot',
    //   url: appEnv.contentUrls.crowbot,
    //   shortText: 'AI chatbot modeled after ASU President Dr. Michael Crow’s speeches.',
    //   mediumText:
    //     'Crowbot is an AI model trained on ASU President Dr. Michael Crow’s numerous insights and writings, It provides information and summarizes key perspectives from Dr. Crow on topics related to higher education, innovation, technology and leadership.'
    // }
  ];

  return CONTENT;
};
