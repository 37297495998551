import Box from '@mui/joy/Box';

const PulseAnimator = () => {
  return (
    <Box position="relative" width={50} height={50}>
      <div className="pulse-circle" />
      <div className="pulse-circle" />
      <div className="pulse-circle" />
    </Box>
  );
};

export default PulseAnimator;
