import { useEffect } from 'react';
import BetaMap from './components/BetaMap/BetaMap';
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import theme from './styles/theme';
import { navigateToLogin, validCookieToken } from './utils/helpers';
const isProduction = process.env.NODE_ENV === 'production';

function App() {
  useEffect(() => {
    function checkAuthCookie() {
      const isValid = validCookieToken();
      if (!isValid && isProduction) {
        navigateToLogin();
      }
    }

    checkAuthCookie();
  }, []);

  return (
    <CssVarsProvider defaultMode="dark" theme={theme}>
      <CssBaseline />
      <BetaMap />
    </CssVarsProvider>
  );
}

export default App;
