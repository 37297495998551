import Link from '@mui/joy/Link';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import { BsArrowRight } from 'react-icons/bs';

interface FeatureCardProps {
  title: string;
  description: string;
  url: string;
  active: boolean;
  onSelectCard: () => void;
}

const FeatureCard = ({ title, description, url, active, onSelectCard }: FeatureCardProps) => {
  return (
    <Box
      width={316}
      pt={3.5}
      px={2.25}
      pb={2.125}
      borderRadius={10}
      sx={{
        border: '2px solid #484848',
        background: '#191919',
        transition: 'all 0.3s ease-in-out',
        borderColor: active ? '#FFC627' : '#484848'
      }}
      onClick={onSelectCard}
    >
      <Typography level="title-lg" color="primary" mb={0.5}>
        {title}
      </Typography>
      <Typography
        level="body-sm"
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: '2',
          WebkitBoxOrient: 'vertical'
        }}
      >
        {description}
      </Typography>
      <Link href={url} target="_blank" sx={{ fontSize: 14, color: '#fff', mt: 2, fontWeight: 700 }}>
        Launch App&nbsp;&nbsp;
        <BsArrowRight />
      </Link>
    </Box>
  );
};

export default FeatureCard;
