import { useState } from 'react';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import Tooltip from '@mui/joy/Tooltip';
import Link from '@mui/joy/Link';
import IconButton from '@mui/joy/IconButton';
import { styled } from '@mui/joy';
import { BsArrowRight } from 'react-icons/bs';
import { MdClose } from 'react-icons/md';
import { useMediaQuery } from 'usehooks-ts';
import PulseAnimatior from '../PulseAnimatior';
import LogoView from '../LogoView';
import FeatureCard from '../FeatureCard';
import ImgBetaMap from '../../assets/images/betamap.jpg';
import ImgGem5 from '../../assets/images/gem5.png';
import { mapContent } from '../../utils/helpers';

const Image = styled('img')({
  width: 80,
  aspectRatio: 240 / 355,
  transition: 'all 0.5s ease-in-out',
  '&:hover': {
    filter: 'drop-shadow(0 0 2px #fff)'
  }
});

const BetaMap = () => {
  const [activeGem, setActiveGem] = useState<number | null>(null);
  const isTablet = useMediaQuery('(max-width: 1025px)');
  const isMobile = useMediaQuery('(max-width: 576px)');

  const CONTENT = mapContent();
  const handleClickClose = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.stopPropagation();
    setActiveGem(null);
  };

  return (
    <Box sx={{ background: '#05080C 0% 0% no-repeat padding-box', position: 'relative' }}>
      <Stack
        sx={(theme) => ({
          py: 14,
          alignItems: 'center',
          [theme.breakpoints.up('lg')]: {
            display: 'none'
          }
        })}
      >
        <LogoView width={isMobile ? 330 : 375} alignment="center" />
        <Box sx={{ border: '2px solid #FFC627', borderRadius: 10, maxWidth: 300, p: 1, mt: 2.5 }}>
          <Typography level="body-sm">
            Pardon our appearance while we continue to improve, please use a laptop/desktop for an optimal experience
            for now.
          </Typography>
        </Box>
      </Stack>
      <Box
        sx={(theme) => ({
          height: 20,
          background: 'transparent linear-gradient(180deg, #05080C 0%, #05080C00 100%) 0% 0% no-repeat padding-box',
          [theme.breakpoints.up('lg')]: {
            display: 'none'
          }
        })}
      />
      <Box
        sx={(theme) => ({
          position: 'relative',
          height: 1150,
          overflow: 'hidden',
          [theme.breakpoints.down('lg')]: {
            height: 'auto'
          }
        })}
      >
        <img
          src={ImgBetaMap}
          width="100%"
          height="auto"
          style={{ transform: isTablet ? 'none' : 'translateY(-120px)' }}
        />
        {!isMobile &&
          CONTENT.map((item, idx) => {
            const isActive = activeGem === idx;
            return (
              <Stack
                key={idx}
                alignItems="center"
                sx={{
                  position: 'absolute',
                  top: `${(item.y / 1280) * 100}%`,
                  left: `${(item.x / 1920) * 100}%`
                }}
                onClick={() => setActiveGem(idx)}
              >
                <Typography
                  level="title-lg"
                  textColor={isActive ? '#FFFFFF' : '#FFC627'}
                  color="primary"
                  sx={(theme) => ({
                    backgroundColor: theme.palette.background.body,
                    mb: 1,
                    px: 2,
                    py: 1,
                    lineHeight: '17px',
                    borderRadius: 10
                  })}
                >
                  {item.name}
                </Typography>
                <Tooltip
                  title={
                    <ClickAwayListener onClickAway={() => setActiveGem(null)}>
                      <Box sx={{ position: 'relative', width: 400, p: 2 }}>
                        <IconButton sx={{ position: 'absolute', top: 0, right: 0 }} onClick={handleClickClose}>
                          <MdClose />
                        </IconButton>
                        <Typography level="title-lg" textColor="#ffffff" letterSpacing="-0.27px">
                          {item.name}
                        </Typography>
                        <Typography level="body-sm">{item.mediumText}</Typography>
                        <Link
                          href={item.url}
                          target="_blank"
                          sx={{ fontSize: 14, color: '#fff', mt: 2, fontWeight: 700 }}
                        >
                          Launch App&nbsp;&nbsp;
                          <BsArrowRight />
                        </Link>
                      </Box>
                    </ClickAwayListener>
                  }
                  open={isActive}
                  arrow
                  variant="outlined"
                  placement="top"
                  size="lg"
                  sx={{
                    boxShadow: 'none',
                    borderWidth: 2,
                    borderColor: '#FFC627',
                    borderRadius: 10,
                    '--Tooltip-arrowSize': '20px',
                    '--variant-borderWidth': '2px',
                    '--variant-outlinedBorder': '#FFC627'
                  }}
                >
                  <Box position="relative">
                    <Image
                      src={ImgGem5}
                      alt={item.name}
                      sx={{ filter: isActive ? 'drop-shadow(0 0 2px #fff)' : 'none' }}
                    />
                    {isActive && (
                      <Box position="absolute" sx={{ left: '50%', transform: 'translateX(-50%)', bottom: 0 }}>
                        <PulseAnimatior />
                      </Box>
                    )}
                  </Box>
                </Tooltip>
              </Stack>
            );
          })}
        {(!isTablet || isMobile) && (
          <Stack
            direction={isMobile ? 'column' : 'row'}
            justifyContent="space-between"
            columnGap={2}
            sx={{
              position: isMobile ? 'initial' : 'absolute',
              width: '100%',
              top: 'calc(100% - 400px)',
              px: isMobile ? 2 : 9,
              py: isMobile ? 3 : 0,
              alignItems: 'flex-start'
            }}
          >
            {!isMobile && <LogoView width={364} />}
            <Stack direction={isMobile ? 'column' : 'row'} flexWrap="wrap" mx="auto" gap={2}>
              {CONTENT.map((item, idx) => (
                <FeatureCard
                  key={item.name}
                  title={item.name}
                  description={item.shortText}
                  url={item.url}
                  active={idx === activeGem}
                  onSelectCard={() => setActiveGem(idx)}
                />
              ))}
            </Stack>
          </Stack>
        )}
      </Box>
    </Box>
  );
};

export default BetaMap;
