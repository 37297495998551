import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import LogoBetaLand from '../../assets/svgs/betaland-logo.svg';

interface LogoViewProps {
  width: number;
  alignment?: 'left' | 'center';
}

const LogoView = ({ width, alignment = 'left' }: LogoViewProps) => {
  return (
    <Box
      minWidth={width}
      width={width}
      sx={{ textAlign: alignment, flexDirection: 'column', display: 'flex', alignItems: 'center' }}
    >
      <img src={LogoBetaLand} width="100%" height="auto" />
      <Typography level="body-md">
        An interactive platform for ASU’s AI beta and proof of concept applications.
      </Typography>
    </Box>
  );
};

export default LogoView;
